<template>
    <div class="gameDetail">
        <!-- banner -->
        <picture class="banner">
            <source srcset="../../assets/imgs/h5_images/h5_game_detail_lucky7_daily.jpg" media="(max-width: 414px)">
            <img src="../../assets/imgs/gameBanner/game_detail_lucky7_daily.jpg">
        </picture>
        <!--games -->
        <main>
            <div class="content">
                <div class="left"><img src="../../assets/imgs/gameIcon/lucky_7_daily.png"></div>
                <div class="right">
                    <div class="title">
                        <div> Lucky 7</div>
                         <div class="tagWraper">
                            <div class="tag"><img src="../../assets/imgs/tagIcon/daily.png"></div>
                            <div class="tag"><img src="../../assets/imgs/tagIcon/daily_24h.png"></div>
                            <div class="tag"><img src="../../assets/imgs/tagIcon/daily_99rtp.png"></div>
                            <div class="tag"><img src="../../assets/imgs/tagIcon/daily_bmm.png"></div>
                            <div class="logoOld"><img src="../../assets/imgs/maxgate_old_icon/Lucky7_Daily.png"></div>
                         </div>
                    </div>
                    <div class="introduce pc">
                        Lucky 7 game is a RNG game that comes up with 49 balls numbered 1-49. 6 regular balls and 1 bonus ball will be drawn. The number cannot be repeated.  Players are allowed to select an individual number on the regular ball or on the bonus ball. 6 regular balls and 1 bonus ball are randomly generated. If the player select an individual number on the regular ball, any of the regular balls match the player’s selected number they win. In case, the player select an individual number on the bonus ball, the player wins when the number on the bonus ball matches the player’s selection.
                    </div>
                    <a class="playBtn" href="https://demo.maxgatesoftware.com/#/Lucky7/Daily">Play Demo</a>
                </div>
            </div> 
            <div class="introduce mobile">
                 Lucky 7 game is a RNG game that comes up with 49 balls numbered 1-49. 6 regular balls and 1 bonus ball will be drawn. The number cannot be repeated.  Players are allowed to select an individual number on the regular ball or on the bonus ball. 6 regular balls and 1 bonus ball are randomly generated. If the player select an individual number on the regular ball, any of the regular balls match the player’s selected number they win. In case, the player select an individual number on the bonus ball, the player wins when the number on the bonus ball matches the player’s selection.
            </div>
            <div class="picWrapper pc">
                <div class="pic"><img src="../../assets/imgs/gamedetail/daily_1.jpg"></div>
                <div class="pic"><img src="../../assets/imgs/gamedetail/daily_2.jpg"></div> 
                <div class="pic"><img src="../../assets/imgs/gamedetail/daily_3.jpg"></div>  
            </div>
            <div class="picWrapper mobile">
                <div class="pic"><img src="../../assets/imgs/h5_images/h5_screenshot_daily.jpg"></div>
            </div>
        </main>
        <!--infor -->
        <section style="background:rgb(246, 246, 247);box-shadow: 2px 2px 3px rgba(161,161,161,0.5) inset">
            <div class="infor">
                <div class="left">
                    <div class="subject">INTRODUCTION</div>
                    <div class="features">
                        <div class="title">How To Play</div>
                        <div class="desc">
                            <p>To play Lucky 7, firstly you might select the Regular Ball or Bonus Ball to have different bet options.</p>
                            <p>You can place your bet on your chosen number by selecting the corresponding number button.</p>
                        </div>
                    </div>
                    <div class="features">
                        <div class="title">Placing Bets</div>
                        <div class="desc">
                            <p>Lucky 7 allows you to place your stake GBP 1, 5, 10, 50 by clicking different chip icon.</p>
                            <p>The amount of your stake will be shown in the field.</p>
                            <p>You might also enter the amount of your bet in the field.</p>
                            <p>Select the “Bet” button to confirm your bet.</p>
                            <p>You will see your transaction record on “Your Recent Betslip” once your bet is confirmed.</p>
                        </div>
                    </div>
                    <div class="features">
                        <div class="title">Getting Started</div>
                        <div class="desc">
                            <p>Lucky 7 Express draws at 01:00am every day.</p>
                            <p>The timer shows the remaining time for the next game.</p>
                            <p>The draw result is shown on the tab.</p>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="subject">GAME SPECIFICATIONS</div>
                    <div class="features">
                        <div class="title">Supported Platform</div>
                        <div class="desc">
                            <p>Desktop, Mobile.</p>
                        </div>
                    </div>
                    <div class="features">
                        <div class="title">Game Logic</div>
                        <div class="desc">
                            <p>Draw 7 balls(6 regular balls and 1 bonus ball) between numbered 1-49, the number cannot be repeated.</p>
                        </div>
                    </div>
                    <div class="features">
                        <div class="title">RTP</div>
                        <div class="desc">
                            <p>The return to player percentage (RTP%) for this game is 97.96%.</p>
                        </div>
                    </div>
                    <div class="features">
                        <div class="title">Payouts</div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Bet Type</th>
                                    <th>Selection</th>
                                    <th>Odds</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Standard Number</td>
                                    <td>Number</td>
                                    <td>8</td>
                                </tr>
                                <tr>
                                    <td>Bonus Ball</td>
                                    <td>Number</td>
                                    <td>48</td>
                                </tr>
                                <tr>
                                    <td>Draw Sum/td>
                                    <td>Odd</td>
                                    <td>1.98</td>
                                </tr>
                                <tr>
                                    <td>Draw Sum</td>
                                    <td>Even</td>
                                    <td>1.98</td>
                                </tr>
                                <tr>
                                    <td>Correct Number</td>
                                    <td>Number</td>
                                    <td>9.85</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
        <!--history -->
        <PeriodHistory lotteryCode="Lucky7Daily"/>
        <!--more game -->
        <div class="moreGame">
            <h1>MORE GAMES</h1>
            <div class="gameWrapper">
                <a href="/#/games/Lucky5_Regular">
                <div class="imgBox"><img src="../../assets/imgs/gameIcon/lucky_5_regular.png"></div>
                </a>
                <a href="/#/games/Infinity8_Regular">
                <div class="imgBox"><img src="../../assets/imgs/gameIcon/infinity_8_regular.png"></div>
                </a>            
                <a href="/#/games/Cannon20_Regular">
                <div class="imgBox"><img src="../../assets/imgs/gameIcon/cannon_20_regular.png"></div>
                </a>
                <a href="/#/games/Gamma3_Express">
                <div class="imgBox"><img src="../../assets/imgs/gameIcon/gamma_3_express.png"></div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import PeriodHistory from '@/components/PeriodHistory';
import 'vue2-datepicker/index.css';
import lib from '@/lib';
export default {
    components: { DatePicker,PeriodHistory },
}
</script>
